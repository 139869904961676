import dayjs from 'dayjs';
import jsPDF from 'jspdf';

export function createPdf(screenings, selected, user) {
    const fieldsPDF = [
        { key: 'id', label: 'No' },
        { key: 'customerName', label: 'Name' },
        { key: 'customerAddress', label: 'Address' },
        { key: 'nationalID', label: 'National ID' },
        { key: '', label: 'Balance' },
        { key: '', label: 'Monthly Payment' },
        { key: '', label: 'Comments' },
    ];

    let screeningSelected = [];

    screenings.forEach((screening) => {
        screening.customerScreeningRecords.forEach((record) => {
            if (selected.includes(record.customerId)) {
                screeningSelected.push({
                    id: record.id,
                    customerName: record.customerName,
                    customerAddress: record.customerAddress,
                    nationalID: record.nationalId,
                });
            }
        });
    });

    screeningSelected = screeningSelected.flatMap((screening, index) => [
        {
            ...screening,
            id: index + 1,
        },
        {}, // Blank row
    ]);

    const doc = new jsPDF();
    const img = new Image();
    img.src = '/TOTAL-Icon-Teal.png';
    doc.addImage(img, 'PNG', 12, 10, 12, 12);

    // Establecer color de texto
    doc.setTextColor(10, 80, 93);

    // Encabezado centrado
    doc.setFontSize(18);
    doc.setFont('Helvetica', 'bold');
    doc.text('Screening Form', 105, 20, { align: 'center' });

    const currentDateAsText = dayjs().format('dddd, DD MMMM YYYY');
    doc.setFontSize(10);
    doc.setFont('Helvetica');

    // Información de la empresa
    doc.text('Mack’s Total Finance VBA', 14, 30);
    doc.text('Adriaan Laclé Blvd 28, lok 7', 14, 35);
    doc.text('Oranjestad, Aruba', 14, 40);

    doc.setFont('Helvetica', 'bold');
    doc.text(`Date: ${currentDateAsText}`, 14, 50);
    doc.setFont('Helvetica', 'normal');
    //insert a line
    doc.setLineWidth(0.2); // Grosor de la línea
    doc.setDrawColor(10, 80, 93); // Color de la línea
    doc.line(14, 52, 196, 52);

    doc.text(
        'On behalf of Total Finance, we would appreciate your assistance with the screening of the following customer(s).',
        14,
        60,
    );
    doc.setFont('Helvetica', 'bold');
    doc.text('Email to:', 14, 65);

    doc.setFont('Helvetica', 'normal');
    doc.text('screening@total-finance.net', 38, 65);

    doc.setLineWidth(0.2); // Grosor de la línea
    doc.setDrawColor(10, 80, 93); // Color de la línea
    doc.line(14, 67, 196, 67);

    doc.setFontSize(16);
    doc.setFont('Helvetica', 'normal');

    const tableColumns = fieldsPDF.map((field) => field.label);

    const tableRows = screeningSelected.map((item) =>
        fieldsPDF.map((field) => {
            const value = item[field.key] || '';
            return value;
        }),
    );

    doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 75,
        theme: 'grid',
        headStyles: { fillColor: [10, 80, 93], textColor: 255 },
        styles: { textColor: [10, 80, 93], fontSize: 8 },
        columnStyles: {
            2: { cellWidth: 30 },
        },
        margin: { bottom: 50 },
    });

    // Pie de página solo en la última página
    const pageCount = doc.internal.getNumberOfPages();
    doc.setPage(pageCount);
    doc.setFontSize(10);
    doc.text(`Page ${pageCount} of ${pageCount}`, 14, 290);
    doc.text('Thank you in advance for the collaboration', 14, 265);
    doc.text('Regards,', 14, 270);
    doc.setFont('Helvetica', 'bold');
    doc.text(`${user.UserName}`, 14, 275);
    doc.setFont('Helvetica', 'normal');
    doc.text(`${user.Email}`, 14, 280);

    doc.save('screening-requests.pdf');
}
