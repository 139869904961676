<template>
    <b-overlay :show="loadingData" rounded="sm">
        <main class="my-4 bg-light p-4">
            <div class="d-flex justify-content-end mb-2">
                <b-button variant="primary" @click="generatePDF">Generate</b-button>
            </div>
            <div>
                <div class="grid border">
                    <div class="bg-light" v-for="field in lvl1Fields" :key="field.key">
                        <div class="p-2" style="font-weight: bold">{{ field.label }}</div>
                    </div>
                </div>
                <div class="grid border" v-for="(item, idx) in screenings" :key="idx">
                    <div class="" v-for="field in lvl1Fields" :key="field.key">
                        <div class="p-2">
                            <span v-if="field.key === 'action'">
                                <b-button v-b-toggle="`accordion-${idx}`" variant="primary" size="sm"
                                    >Toggle Details</b-button
                                >
                            </span>

                            <span v-if="field.key === 'numberOfClients'">
                                {{ item.customerScreeningRecords.length }}
                            </span>
                            <span v-else-if="field.key == 'date'">
                                {{ $formatDate(item[field.key]) }}
                            </span>
                            <span v-else-if="field.key === 'screeningsState'" style="display: flex; gap: 12px">
                                <span>
                                    <strong>Completed: </strong>
                                    {{
                                        item.customerScreeningRecords.filter((e) => e.batchState == 'Completed').length
                                    }}
                                </span>
                                <span>
                                    <strong>Pending: </strong>
                                    {{ item.customerScreeningRecords.filter((e) => e.batchState == 'Pending').length }}
                                </span>
                                <span>
                                    <strong>Submitted: </strong>
                                    {{
                                        item.customerScreeningRecords.filter((e) => e.batchState == 'Submitted').length
                                    }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <b-collapse
                        :id="`accordion-${idx}`"
                        :visible="idx == 0"
                        class="w-100"
                        style="grid-column: span 9 / span 9"
                    >
                        <div class="px-3 pb-2">
                            <b-table-simple show-empty responsive small hover>
                                <b-thead head-variant="light">
                                    <b-tr>
                                        <b-th v-for="head in lvl2Fields" :key="head.key">
                                            {{ head.label }}
                                        </b-th>
                                        <b-th>
                                            <b-form-checkbox v-model="selectAll" @change="selectAllHandler">
                                            </b-form-checkbox>
                                        </b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="item in item.customerScreeningRecords" :key="item.customerId">
                                        <b-td v-for="field in lvl2Fields" :key="field.key">
                                            <span v-if="field.key == 'date'">
                                                {{ $formatDate(item[field.key], true) }}
                                            </span>
                                            <span v-else-if="field.key == 'view'">
                                                <b-button
                                                    @click="$router.push(`/customers/list/${item.customerId}`)"
                                                    variant="primary"
                                                    size="sm"
                                                    >View</b-button
                                                >
                                            </span>
                                            <span v-else>{{ item[field.key] }}</span>
                                        </b-td>
                                        <b-th>
                                            <b-form-checkbox
                                                v-model="selected"
                                                :value="item.customerId"
                                            ></b-form-checkbox>
                                        </b-th>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </b-collapse>
                </div>
            </div>

            <div class="p-3 d-flex align-items-center">
                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="[
                            { text: '5', value: 5 },
                            { text: '10', value: 10 },
                            { text: '20', value: 20 },
                            { text: '50', value: 50 },
                            { text: '100', value: 100 },
                        ]"
                        size="sm"
                        @change="fetchScreenings"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="screenings.length"
                        :per-page="perPage"
                        align="fill"
                        @change="fetchScreenings"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
        </main>
    </b-overlay>
</template>

<script>
import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import { createPdf } from './screening-document';
export default {
    name: 'AllScreeningHistory',
    data: () => ({
        lvl1Fields: [
            {
                key: 'date',
                label: 'Request Date',
            },
            {
                key: 'numberOfClients',
                label: 'Number of Clients',
            },
            {
                key: 'screeningsState',
                label: 'Screenings State',
            },

            {
                key: 'action',
                label: 'Actions',
            },
        ],
        lvl2Fields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'date',
                label: 'Request Date',
            },
            {
                key: 'nationalId',
                label: 'National ID',
            },
            {
                key: 'customerName',
                label: 'Full Name',
            },
            {
                key: 'customerAddress',
                label: 'Address',
            },
            {
                key: 'createdByUserName',
                label: 'Loan Officer',
            },
            {
                key: 'employedAtBank',
                label: 'Employed at Bank',
            },
            {
                key: 'batchState',
                label: 'State',
            },
            {
                key: 'view',
                label: 'View',
            },
            {
                key: 'action',
                label: '',
            },
        ],
        loadingData: false,
        screenings: [],
        perPage: 10,
        currentPage: 1,
        selected: [],
        selectAll: false,
    }),
    methods: {
        selectAllHandler() {
            if (this.selectAll) {
                this.selected = this.screenings.reduce((acc, screening) => {
                    return [...acc, ...screening.customerScreeningRecords.map((record) => record.customerId)];
                }, []);
            } else {
                this.selected = [];
            }
        },
        async fetchScreenings() {
            this.loadingData = true;
            const data = {
                page: this.currentPage,
                recordsPerPage: this.perPage,
                sortDirection: null,
                sortProperty: null,
            };
            const response = await this.$store.dispatch('customer/screening/get_allScreeningHistory', data);

            this.screenings = response ?? [];
            this.loadingData = false;
        },
        generatePDF() {
            createPdf(this.screenings, this.selected, this.user);
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'authorizations/getAuthorizations',
            user: 'auth/getUser',
        }),
    },
    created() {
        this.fetchScreenings();
    },
};
</script>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
</style>
